/**=====================
    3.10 Dashboard_2 CSS Start
==========================**/
.widget-decor {
  position: absolute;
  height: 60px;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
}

.e-common-button {
  .btn {
    &:first-child {
      margin-right: 12px;

      @media (max-width:356px) {
        margin-bottom: 8px;
      }
    }

    svg {
      width: 18px;
      height: 18px;
      vertical-align: text-bottom;
      margin-right: 6px;
    }
  }
}

.main-title-box {
  .common-space {
    flex-wrap: wrap;

    @media (max-width:1254px) {
      display: block;

      h6 {
        margin-bottom: 12px;
        line-height: 1.5;
      }
    }
  }
}

.balance-widget {
  background-image: url(../images/dashboard-2/balance-bg.png);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: right;

  .purchase-btn {
    @media (max-width:400px) {
      font-size: 14px;
    }
  }

  .mobile-right-img {
    position: absolute;
    top: 60%;
    right: -15%;
    transform: translate(-50%, -50%);

    @media (max-width:1475px) and (min-width:1400px) {
      right: -18%;
    }

    @media (max-width:1399px) and (min-width:936px) {
      right: -10%;
    }

    @media (max-width:935px) and (min-width:768px) {
      right: -15%;
    }

    @media (max-width:767px) and (min-width:621px) {
      right: -35px;
    }

    @media (max-width:620px) and (min-width:521px) {
      right: -60px;
    }

    @media (max-width:520px) {
      right: -75px;
    }

    .mobile-img {
      height: 110px;

      @media (max-width:865px) and (min-width:787px) {
        height: 90px;
      }

      @media (max-width:786px) and (min-width:768px) {
        height: 80px;
      }

      @media (max-width:767px) {
        height: 104px;
      }
    }

    @media (max-width: 475px) and (min-width:436px) {
      right: -52px;

      .mobile-img {
        height: 82px;
      }
    }

    @media (max-width: 435px) and (min-width:401px) {
      right: -46px;

      .mobile-img {
        height: 70px;
      }
    }

    @media (max-width: 400px) {
      right: -35px;
      top: 38%;

      .mobile-img {
        height: 55px;
      }
    }
  }

  [dir="rtl"] & {
    text-align: right;
  }

  &.card-body {
    padding: 20px 15px;
  }

  .purchase-btn {
    min-width: 170px;
  }
}

.small-widget {
  overflow: hidden;

  h4 {
    margin-bottom: -3px;
  }

  i {
    font-weight: 700;
    font-size: 11px;
  }

  .card-body {
    padding: 24px 15px;
  }

  .bg-gradient {
    width: 66px;
    height: 67px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 100px;
    right: -12px;
    top: 50%;
    position: absolute;
    transform: translateY(-50%);

    [dir="rtl"] & {
      right: unset;
      left: -12px;
      transform: translateY(-50%) scaleX(-1);
    }

    @media (max-width: 1399px) {
      width: 60px;
      height: 60px;
    }

    svg {
      width: 25px;
      height: 25px;

      @media (max-width: 1399px) {
        width: 22px;
        height: 22px;
      }
    }
  }

  &:hover {
    transform: translateY(-5px);
    transition: 0.5s;

    .bg-gradient {
      svg {
        animation: tada 1.5s ease infinite;
      }
    }
  }
}

.appointment {
  .customer-table {
    // @media (max-width:1470px) {
    //   height: 268px;
    // }

    // @media (max-width:1399px) {
    //   height: unset;
    // }
  }
}

.order-wrapper {
  margin: 0 0px -20px -16px;
}

.categories-list {
  display: flex;
  flex-direction: column;
  gap: 18px;

  @media (max-width: 767px) {
    flex-direction: row;
    flex-wrap: wrap;
  }

  li {
    gap: 10px;

    .bg-light {
      min-width: 40px;
      height: 40px;
      display: flex;
      align-items: center;
      justify-content: center;

      img {
        width: 25px;
        height: 25px;
        object-fit: contain;
        transition: 0.5s;
      }
    }

    h6 {
      a {
        transition: 0.5s;
        color: var(--body-font-color);
      }
    }

    &:hover {
      .bg-light {
        img {
          transition: 0.5s;
          transform: scale(1.1);
        }
      }

      h6 {
        a {
          transition: 0.5s;
          color: var(--theme-default);
        }
      }
    }
  }
}

.monthly-profit {
  margin-top: -10px;

  @media (max-width: 1584px) {
    margin: -10px -8px 0;
  }

  @media (max-width: 1520px) {
    margin: -10px -16px 0;
  }

  @media (max-width:1500px) {
    margin: -10px -14px 0;
  }

  @media (max-width:1472px) {
    margin: -10px -20px 0;
  }

  @media (max-width:1424px) {
    margin: -10px -25px 0;
  }

  .apexcharts-canvas {
    .apexcharts-legend-marker {
      margin-right: 6px;
    }

    .apexcharts-datalabels-group {
      .apexcharts-datalabel-value {
        font-size: 14px;
        font-weight: 500;
        font-family: $font-rubik, $font-serif !important;
        fill: var(--chart-text-color);
      }
    }

    .apexcharts-tooltip {
      .apexcharts-tooltip-series-group {
        .apexcharts-tooltip-y-group {
          [class*="apexcharts-tooltip-text-y-"] {
            color: var(--white);
          }
        }
      }
    }
  }
}

.overview-wrapper {
  position: relative;
  z-index: 1;
}

.back-bar-container {
  position: absolute;
  width: calc(100% - 64px);
  bottom: -8px;
  margin: 0 auto !important;
  left: 28px;

  @media (max-width: 1199px) {
    bottom: 22px;
  }

  @media (max-width: 480px) {
    width: calc(100% - 34px);
    left: 18px;
  }

  @media (max-width: 327px) {
    bottom: 42px;
  }

  @media (max-width:342px) {
    margin-bottom: 24px !important;
  }
}

.overview-card {

  @media (max-width:850px) and (min-width:768px) {
    .w-md-100 {
      width: 100%;
    }

    .ds-md-none {
      display: none !important;
    }
  }

  .balance-data {
    right: -158px;
    top: -43px;

    @media (max-width:1808px) and (min-width:1759px) {
      right: -150px;
    }

    @media (max-width:1758px) and (min-width:1400px) {
      right: -142px;
    }

    @media (max-width:1399px) and (min-width:1346px) {
      right: -175px;
    }

    @media (max-width:1345px) and (min-width:1291px) {
      right: -162px;
    }

    @media (max-width:1290px) and (min-width:1236px) {
      right: -150px;
    }

    @media (max-width:1235px) and (min-width:1050px) {
      right: -135px;
    }

    @media (max-width:1049px) and (min-width:991px) {
      right: -124px;
    }

    @media (max-width:990px) {
      display: none;
    }

    [dir="rtl"] & {
      right: unset;
      left: 12px;
    }

    @media (max-width: 767px) {
      right: -40%;

      [dir="rtl"] & {
        left: -40%;
      }
    }
  }
}

.order-container {
  .apexcharts-canvas {
    .apexcharts-marker {
      stroke-width: 4;
    }
  }
}

.purchase-card.discover {
  margin-top: 90px;

  .card-body {
    p {
      @media (max-width:1488px) {
        display: block;
        display: -webkit-box;
        max-width: 100%;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      @media (max-width:1143px) and (min-width:992px) {
        -webkit-line-clamp: 1;
      }
    }
  }

  img {
    width: 216px;
    margin: 0 auto;
    margin-top: -90px;

    @media (max-width:1638px) and (min-width:1200px) {
      width: 200px;
    }

    @media (max-width:1199px) and (min-width:992px) {
      width: 228px;
    }

    @media (max-width: 1399px) and (min-width:992px) {
      margin-top: -75px;
    }

    @media (max-width:991px) {
      width: 204px;
    }

    @media (max-width: 618px) {
      width: 200px;
    }
  }
}

.visitor-card {
  .card-header {
    svg {
      width: 18px;
      height: 18px;
      fill: var(--theme-default);
    }
  }
}

.visitors-container {
  margin: 0 -12px -27px -17px;

  svg {
    .apexcharts-series {
      path {
        clip-path: inset(1% 0% 0% 0% round 3rem);
      }
    }

    .apexcharts-legend.apexcharts-align-left {
      .apexcharts-legend-series {
        display: flex;
      }
    }
  }
}

.recent-order {
  .nav {
    gap: 8px;
    flex-wrap: nowrap;
    overflow: auto;
    padding-bottom: 5px;
    display: flex;
  }

  .frame-box {
    border: 1px solid transparent;
    padding: 0;
    transition: 0.5s;

    &.active {
      border: 1px solid var(--theme-default);
    }

    &:hover {
      transform: translateY(-5px);
      transition: 0.5s;
    }
  }

  .tab-content {
    margin-top: 16px;
  }

  .recent-items {
    table {

      thead,
      tbody {
        tr {

          th,
          td {
            &:first-child {
              min-width: 175px;
            }

            &:nth-child(2) {
              min-width: auto;
            }
          }
        }
      }
    }
  }
}

.recent-table {
  table {
    thead {
      background: var(--light2);

      th {
        padding-top: 9px;
        padding-bottom: 9px;
        border-bottom: none;
      }
    }

    tr {

      td,
      th {
        padding: 12px 8px;
        vertical-align: middle;

        &:first-child {
          min-width: 157px;
        }

        &:nth-child(2) {
          @media (max-width: 1660px) {
            min-width: 97px;
          }
        }

        &:last-child {
          min-width: 96px;
        }
      }

      td {
        &:first-child {
          padding-left: 0;

          [dir="rtl"] & {
            padding-left: unset;
            padding-right: 0;
          }
        }

        &:last-child {
          padding-right: 0;

          [dir="rtl"] & {
            padding-left: 0;
            padding-right: unset;
          }
        }
      }

      .product-content {
        h6 {
          a {
            color: var(--body-font-color);
            transition: 0.5s;
          }
        }
      }

      &:hover {
        .product-content {
          h6 {
            a {
              color: var(--theme-default);
              transition: 0.5s;
            }
          }
        }
      }
    }
  }

  .product-content {
    display: flex;
    align-items: center;
    gap: 8px;

    .order-image {
      background: var(--light2);
      width: 40px;
      height: 40px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  svg {
    width: 20px;
    height: 20px;
  }

  .recent-status {
    display: flex;
    align-items: center;

    &.font-success {
      svg {
        fill: $success-color;
      }
    }

    &.font-danger {
      svg {
        fill: $danger-color;
      }
    }
  }
}

.recent-activity {
  h5 {
    padding: 30px 0 20px;
    margin-bottom: 0;
  }

  h6 {
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-line-clamp: 2;
    display: -webkit-box;
  }

  ul {
    li {
      span {
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
        -webkit-line-clamp: 2;
        display: -webkit-box;
      }

      &:last-child {
        padding-bottom: 0;
      }
    }
  }
}

.notification {
  li {
    .recent-images {
      ul {
        &::before {
          display: none;
        }
      }

      li {
        padding-bottom: 3px;
      }
    }
  }
}

.recent-images {
  margin-top: 10px;

  ul {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    gap: 8px;
  }

  li {
    border: 1px dashed var(--recent-dashed-border);
    padding: 3px;
    border-radius: 2px;

    .recent-img-wrap {
      width: 40px;
      height: 40px;
      background: var(--light2);
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
}

.frame-box {
  background: var(--recent-box-bg);
  border-radius: 10px;
  min-width: auto;
  box-shadow: 2px 2px 2px var(--recent-border);

  .frame-image {
    min-width: 57px;
    height: 57px;
    border-color: var(--recent-border) var(--white) var(--white) var(--recent-border);
    border-width: 1px;
    border-style: solid;
    margin: 6px;
    display: flex;
    align-items: center;
    border-radius: 4px;

    @media (max-width:1880px) and (min-width:1800px) {
      min-width: 52px;
      height: 52px;
    }

    @media (max-width:1638px) and (min-width:1567px) {
      min-width: 50px;
      height: 50px;
    }

    @media (max-width:1575px) and (min-width:1500px) {
      min-width: 45px;
      height: 45px;
    }

    @media (max-width:1499px) and (min-width:1200px) {
      min-width: 40px;
      height: 40px;
    }

    @media (max-width:608px) {
      min-width: 40px;
      height: 40px;
    }

    @media (max-width: 575px) {
      min-width: 50px;
      height: 50px;
      margin: 4px;
    }
  }

  img {
    margin: 0 auto;

    @media (max-width:1575px) {
      height: 22px;
      width: 30px;
      object-fit: contain;
    }
  }
}

.new-update {
  .d-flex {
    .flex-grow-1 {

      span,
      p {
        font-weight: 500;
      }
    }
  }
}

@each $activity-dot-name,
$activity-dot-color in (primary, $primary-color),
(secondary, $secondary-color),
(success, $success-color),
(danger, $danger-color),
(info, $info-color),
(light, $light-color),
(dark, $dark-color),
(warning, $warning-color) {
  .activity-dot-#{$activity-dot-name} {
    min-width: 6px;
    height: 6px;
    background-color: $activity-dot-color;
    border-radius: 100%;
    outline: 5px solid rgba($activity-dot-color, 0.25);
    position: relative;
    z-index: 2;
  }

  // timeline
  .timeline-dot-#{$activity-dot-name} {
    min-width: 12px;
    height: 12px;
    background-color: $activity-dot-color;
    outline: 5px solid rgba($activity-dot-color, 0.25);
    position: relative;
    z-index: 2;

    @media (max-width:1399px) {
      min-width: 10px;
      height: 10px;
      outline: 4px solid rgba($activity-dot-color, 0.25);

    }
  }

}

@media only screen and (max-width: 1800px) and (min-width: 1400px) {
  .grid-ed-none {
    display: none !important;
  }

  .grid-ed-12 {
    width: 100%;
  }
}

@media only screen and (max-width: 1660px) and (min-width: 1400px) {
  .col-ed-12 {
    width: 100%;
  }

  .col-ed-7 {
    width: 58%;
  }

  .col-ed-5 {
    width: 42%;
  }

  .col-ed-9 {
    width: 75%;
  }

  .col-ed-3 {
    width: 25%;
  }

  .col-ed-6 {
    width: 50%;
  }

  .col-ed-4 {
    width: 33.33333333%;
  }

  .col-ed-8 {
    width: 66.66%;
  }

  .col-ed-none {
    display: none !important;
  }
}

@media only screen and (max-width: 1660px) and (min-width: 1200px) {
  .xl-30 {
    max-width: 30%;
    flex: 0 0 30%;
  }

  .xl-70 {
    max-width: 70%;
    flex: 0 0 70%;
  }
}

@media only screen and (max-width: 420px) {
  .size-column {
    .col-6 {
      width: 100%;
    }
  }
}

// Recent transaction
.recent-transaction {
  .dt-layout-row {
    &:last-child {
      @media (max-width:690px) {
        flex-direction: column;
      }
    }

  }

  thead,
  tbody {
    tr {

      th,
      td {
        color: var(--body-font-color);

        &:first-child {
          padding-left: 20px;
        }

        &:last-child {
          padding-right: 20px;
        }

        &:first-child {
          min-width: auto;
        }

        @media (max-width:1454px) and (min-width:1200px) {
          &:nth-child(2) {
            display: none;
          }
        }

        @media (max-width:903px) {
          &:nth-child(2) {
            min-width: 114px;
          }

          &:nth-child(3),
          &:nth-child(4) {
            min-width: 90px;
          }

          &:nth-child(5) {
            min-width: 104px;
          }

          &:nth-child(7) {
            min-width: 110px;
          }

          &:last-child {
            min-width: 108px;
          }
        }
      }
    }
  }

  thead {
    tr {
      th {
        color: $badge-light-color;
      }
    }
  }

}

.stock-report {
  .table-responsive {
    .table {

      thead,
      tbody {
        tr {

          th,
          td {
            color: var(--body-font-color);
            min-width: auto;

            &:first-child {
              padding-left: 20px;
            }

            &:last-child {
              padding-right: 20px;

              @media (max-width:1798px) and (min-width:1400px) {
                display: none;
              }

              .btn {
                min-width: 142px;
              }
            }

            a {
              color: var(--body-font-color);
            }

            .img-border {
              width: 50px;
              height: 50px;
              border-radius: 50%;
              background-color: var(--light2);
              padding: 4px;
              display: flex;
              justify-content: center;
              border: 1px solid var(--chart-progress-light);
              align-items: center;

              img {
                height: 32px;
              }
            }

            @media (max-width:1798px) {
              &:nth-child(4) {
                padding-right: 20px;
              }
            }

            @media (max-width:1543px) and (min-width:1400px) {
              min-width: auto;

              &:last-child {
                display: none;
              }
            }

            @media (max-width:700px) {
              &:first-child {
                min-width: 168px;
              }

              &:nth-child(2) {
                min-width: 104px;
              }

              &:nth-child(3) {
                min-width: 85px;
              }

              &:nth-child(4) {
                min-width: 65px;
              }

              &:last-child {
                min-width: 174px;
              }
            }
          }

          .img-border {
            img {
              transition: 0.5s;
            }
          }

          &:hover {
            td {
              .img-border {
                img {
                  transition: 0.5s;
                  transform: scale(1.1);
                }
              }
            }
          }
        }
      }

      thead {
        tr {
          th {
            color: $badge-light-color;
          }
        }
      }
    }
  }
}


.seller-table {
  .dt-layout-row {
    @media (max-width:886px) {
      .dt-search {
        display: none;
      }
    }
  }

  thead,
  tbody {
    tr {

      th,
      td {
        min-width: auto;
        color: var(--body-font-color);

        a {
          color: var(--body-font-color);
        }

        .common-flex {
          img {
            height: 40px;
          }

          @media (max-width:1213px) and (min-width:1200px) {
            gap: 7px;

            img {
              height: 38px;
            }
          }
        }

        &:first-child {
          padding-left: 20px;
        }

        &:last-child {
          padding-right: 20px;
        }

        &:nth-child(3) {
          @media (max-width:1650px) and (min-width:1200px) {
            display: none;
          }
        }

        @media (max-width:1179px) {
          &:nth-child(2) {
            min-width: 140px;
          }

          &:nth-child(3) {
            min-width: 75px;
          }
        }
      }
    }
  }

  thead {
    tr {
      th {
        color: $badge-light-color;
      }
    }
  }
}

.payment-gateway {
  .pay-box {
    margin-bottom: 13px;
    display: flex;
    gap: 12px;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;

    &:last-child {
      margin-bottom: 0;
    }

    &:hover {
      .outer-svg-box {
        svg {
          animation: tada 1.5s ease infinite;
        }
      }
    }

    .outer-line {
      border-width: 1px;
      border-style: solid;
      padding: 3px;
      border-radius: 50%;

      .outer-svg-box {
        width: 32px;
        height: 32px;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 50%;

        @media (max-width:1652px) {
          width: 29px;
          height: 29px;

          svg {
            width: 16px;
            height: 16px;
          }
        }

        svg {
          width: 18px;
          height: 18px;
        }
      }
    }

    a {
      color: var(--body-font-color);
    }
  }

  @media (max-width:1316px) and (min-width:1200px) {
    .pay-box {
      &:last-child {
        display: none;
      }

      &:nth-child(5) {
        margin-bottom: 0;
      }
    }
  }

  @media (max-width:1141px) and (min-width:992px) {
    .pay-box {
      &:last-child {
        display: none;
      }

      &:nth-child(5) {
        margin-bottom: 0;
      }
    }
  }

  @media (max-width:850px) and (min-width:768px) {
    .pay-box {
      &:last-child {
        display: none;
      }

      &:nth-child(5) {
        margin-bottom: 0;
      }
    }
  }
}

.treading-product {
  .table-responsive {
    .table {

      thead,
      tbody {
        tr {

          th,
          td {
            color: var(--body-font-color);
            min-width: auto;

            &:first-child {
              padding-left: 20px;

              @media (max-width:812px) {
                min-width: 140px;
              }
            }

            &:last-child {
              padding-right: 20px;
            }

            a {
              color: var(--body-font-color);
            }

            .common-flex {
              @media (max-width:1454px) {
                gap: 8px;
              }
            }

            img {
              height: 32px;

              @media (max-width:1552px) and (min-width:1550px) {
                height: 39px;
              }
            }

            @media (max-width:362px) {
              &:first-child {
                min-width: 168px;
              }
            }
          }
        }
      }

      thead {
        tr {
          th {
            color: $badge-light-color;
          }
        }
      }
    }
  }
}

@media (max-width:1399px) and (min-width:768px) {
  .ord-xl-i {
    order: 1;
  }

  .ord-xl-ii {
    order: 2;
  }

  .ord-xl-iii {
    order: 3;
  }

  .ord-xl-iv {
    order: 4;
  }

  .ord-xl-v {
    order: 5;
  }

  .ord-xl-vi {
    order: 6;
  }

  .ord-xl-vii {
    order: 7;
  }

  .ord-xl-viii {
    order: 8;
  }

  .ord-xl-ix {
    order: 9;
  }

  .ord-xl-x {
    order: 10;
  }
}

/**=====================
    3.10 Dashboard_2 CSS End
==========================**/