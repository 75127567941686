/**=====================
    5.6 Select2 CSS Start
==========================**/
// Dropzone
.dropzone {
  background: unset;

  &.dropzone-secondary {
    border: 2px dashed $secondary-color;
    border-radius: 6px;

    i {
      color: $secondary-color;
    }
  }

  .note {
    @media (max-width:360px) {
      min-width: unset !important;
    }
  }

  .dz-message {
    i {
      font-size: calc(40px + (50 - 40) * ((100vw - 320px) / (1920 - 320)));
    }
  }

  .dropzone-wrapper {
    .dz-message {
      i {
        font-size: 50px;

        @media (max-width:575px) {
          font-size: 40px;
        }
      }
    }
  }

  .dz-preview {
    background-color: transparent;
    width: 155px;
    border: 1px dashed var(--recent-border);
    border-radius: 8px;
    padding: 12px 16px;

    .dz-image {
      z-index: 2;
      width: calc(80px + (120 - 80) * ((100vw - 320px) / (1920 - 320))) !important;
      height: calc(80px + (120 - 80) * ((100vw - 320px) / (1920 - 320))) !important;
    }

    .dz-error-mark {
      opacity: 0;
      visibility: hidden;
    }

    .dz-details {
      opacity: 1;
      top: 0;
      padding: 10px 0px 0px;
      left: 0;
      text-align: left;

      .dz-size {
        margin-bottom: 0;
        font-size: 14px;

        span {
          padding: 0 10px;
        }
      }

      .dz-remove {
        padding: 0 10px;

      }
    }

    .dz-progress {
      left: 50%;
      height: 10px;
      transition: all 0.10s linear;

      .dz-upload {
        background: linear-gradient(to bottom, $primary-color, $light-primary);
      }
    }

    .dz-success-mark {
      opacity: 1;
      filter: brightness(0.6);
      top: 32%;
      z-index: 2;
      left: calc(43% + (50 - 43) * ((100vw - 320px) / (1920 - 320))) !important;

      svg {
        width: calc(38px + (54 - 38) * ((100vw - 320px) / (1920 - 320))) !important;
        height: calc(38px + (54 - 38) * ((100vw - 320px) / (1920 - 320))) !important;
      }
    }

    .dz-remove {
      padding: 0 10px;
      text-align: left;
      color: var(--chart-text-color);
    }

    .dz-filename {
      overflow: visible;
      height: 30px;
      padding: 0 10px !important;
      text-align: left;

      &:not(:hover) {
        span {
          border: unset !important;
        }
      }

      span {
        padding: 0 !important;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        max-width: 100px;
      }

      &:hover {
        span {
          border: unset;
        }
      }
    }

    &:hover {
      .dz-filename {
        overflow: visible;
        height: 30px;
      }
    }
  }

  .dz-preview {
    margin: calc(10px + (28 - 10) * ((100vw - 320px) / (1920 - 320))) calc(18px + (18 - 18) * ((100vw - 320px) / (1920 - 320))) !important;
    box-shadow: unset;
    height: 100%;

    .dz-filename {
      padding: 0;

      &:hover {
        span {
          border: unset !important;
        }
      }
    }

    .dz-details {
      top: unset;
      bottom: -6px;
      position: relative;
      padding-bottom: 6px;
      background-color: unset;
    }

    &:hover {
      .dz-error-message {
        display: none !important;
      }
    }
  }

  .dz-error-mark {
    .dz-remove {
      position: relative;
    }
  }
}

.dz-preview {
  .dz-remove {
    cursor: pointer;
  }
}

.dropzone-secondary {
  .dz-preview {
    .dz-remove {
      color: $secondary-color;
    }
  }
}

.badge-light-warning {
  background-color: rgba($warning-color, 0.1);
}

.table {
  thead {
    th {
      border-bottom: 2px solid $light-gray;
    }
  }
}

.select2 {
  max-width: 100%;
}

.add-post {
  #cke_text-box {
    border: 1px solid $light-semi-gray;
  }

  form {
    .m-checkbox-inline {
      label {
        margin-bottom: 0;
      }
    }

    .form-control {
      border: 1px solid $light-semi-gray;
    }

    .col-form-label {
      padding-bottom: 0;
      padding-top: 0;

      .select2-container--default {
        margin-top: 10px;
        max-width: 100%;

        .selection {
          .select2-selection {
            .select2-search__field {
              padding: 2px;
            }
          }
        }

        .select2-selection--multiple {
          border: 1px solid $light-semi-gray;
          max-width: 100%;

          .select2-selection__rendered {
            li {
              margin-top: 6px !important;
            }
          }
        }
      }
    }
  }

  .select2-container--default {
    .select2-selection--multiple {
      .select2-selection__choice {
        background-color: var(--theme-default);
        border: 1px solid var(--theme-default);
        color: $white;

        .select2-selection__choice__remove {
          color: $white;
          float: right;
          margin-left: 0.5rem;

          [dir="rtl"] & {
            float: left;
            margin-left: unset;
            margin-right: 0.5rem;
          }
        }
      }
    }
  }

  .dropzone {
    margin-bottom: 30px;
  }
}

.select2-container--open {
  .select2-dropdown {
    z-index: 7;
  }
}

.select2-container {
  width: 100% !important;

  &.select2 {
    .selection {
      display: block !important;
    }
  }
}

.select2-drpdwn {
  .select2-selection {
    border-radius: 0 !important;
    border-color: $light-color !important;
    height: 40px !important;
    padding: 5px;
  }

  .form-control {
    border-radius: 5px;
  }
}

// Filepond

.filepond--drop-label {
  .filepond--label-action {
    color: $danger-color;
    text-decoration: none;
  }
}

.filepond--list {
  .filepond--file {
    background-color: $primary-color;
  }
}

// Tagify
/* Do not show the "remove tag" (x) button when only a single tag remains */
.customLook {
  display: inline-block;
  min-width: 0;
  border: none;

  .tagify__tag {
    margin-top: 0;

    &:only-of-type {
      .tagify__tag__removeBtn {
        display: none;
      }
    }

    &:hover {
      .tagify__tag__removeBtn {
        transform: none;
        opacity: 1;
        margin-left: -1ch;
      }
    }
  }

  .tagify__tag__removeBtn {
    opacity: 0;
    transform: translateX(-6px) scale(.5);
    margin-left: -3ch;
    transition: .12s;
  }

  + {
    button {
      color: var(--theme-default);
      font: bold 1.4em/1.65 Arial;
      border: 0;
      background: none;
      box-shadow: 0 0 0 2px inset var(--theme-default);
      border-radius: 50%;
      @include pixelstrap-size(1.65em, 1.65em);
      cursor: pointer;
      outline: none;
      transition: .1s ease-out;
      margin: 0 0 0 5px;
      vertical-align: top;

      &:hover {
        box-shadow: 0 0 0 5px inset var(--theme-default);
      }
    }
  }

  .tagify__input {
    display: none;
  }
}

.tagify--empty {
  .tagify__input {
    &::before {
      color: var(--chart-text-color);
    }
  }
}

.customSuggestionsList {
  >div {
    max-height: 300px;
    min-height: 50px;
    border: 2px solid var(--chart-dashed-border);
    overflow: auto;
  }

  .empty {
    color: var(--theme-default);
    font-size: 20px;
    text-align: center;
    padding: 1em;
  }
}

// Flag tags
.tagify__dropdown.extra-properties {
  .tagify__dropdown__item {
    >img {
      display: inline-block;
      vertical-align: middle;
      height: 20px;
      transform: scale(.75);
      margin-right: 5px;
      border-radius: 2px;
      transition: .12s ease-out;
    }

    &:hover {
      >img {
        transform: none;
        margin-right: 12px;
      }
    }
  }

  .tagify__dropdown__item--active {
    >img {
      transform: none;
      margin-right: 12px;
    }
  }
}

.tagify.countries {
  .tagify__input {
    min-width: 175px;
  }

  tag {
    white-space: nowrap;

    img {
      display: inline-block;
      height: 16px;
      margin-right: 3px;
      border-radius: 2px;
      pointer-events: none;
    }
  }
}

.tagify--select {
  width: 100%;
}

.tagify__dropdown__wrapper {
  background: var(--white);
  border-color: var(--recent-dashed-border);
}

.tagify__dropdown__item--active {
  background-color: $gray-60;
  color: var(--body-font-color);
}

.tagify {
  --tags-focus-border-color: #cccccc;
}

.tagify__input {
  color: $dark-color;
}

.tagify--select {
  &::after {
    [dir="rtl"] & {
      right: unset;
      left: 0;
      transform: translate(150%, -50%) scaleX(1.2) rotate(90deg);
    }

    color: var(--chart-text-color);
  }

  .tagify__tag {
    [dir="rtl"] & {
      right: unset;
      left: 1.8em;
    }
  }
}

.tagify--select[aria-expanded=true]::after {
  [dir="rtl"] & {
    transform: translate(150%, -50%) rotate(270deg) scale(1.2);
  }
}

.international-num {
  input {
    width: calc(240px + (250 - 240) * ((100vw - 320px) / (1920 - 320)));
    padding: 10px;
    border-radius: 2px;
    border: 1px solid $gray-60;

    &:focus {
      outline-color: $gray-60;
    }
  }

  input::placeholder {
    color: $gray-60;
  }

  .results {
    ul {

      border: 1px solid $gray-60;
      margin-top: 12px;
      padding: 8px;
      line-height: 1.9;
    }
  }
}

.iti--allow-dropdown {
  .iti__flag {
    background-image: url('../images/forms/flags.png');
  }
}

// Search-box
.select-box {
  position: relative;
  display: flex;
  width: 400px;
  flex-direction: column;

  .options-container {
    background: var(--recent-box-bg);
    color: var(--body-font-color);
    max-height: 0;
    width: 100%;
    opacity: 0;
    transition: all 0.4s;
    border-radius: 0 0 8px 8px;
    overflow: hidden;
    order: 1;
  }

  .options-container.active {
    max-height: 240px;
    opacity: 1;
    overflow-y: scroll;
    margin-top: 53px;

    @media (min-width:1007px) and (max-width:1920px) {
      max-width: calc(265px + (400 - 265) * ((100vw - 1007px) / (1920 - 1007)));
    }

    @media (min-width:768px) and (max-width:1006px) {
      max-width: calc(295px + (350 - 295) * ((100vw - 1007px) / (1006 - 768)));
    }

    @media (max-width:767px) {
      max-width: calc(242px + (415 - 242) * ((100vw - 320px) / (767 - 320)));
      margin-top: 46px;
    }

    + {
      .selected-box {
        &::after {
          transform: rotateX(180deg);
          top: -6px;
        }
      }
    }

    ~ {
      .search-box {
        input {
          opacity: 1;
          pointer-events: auto;
        }
      }
    }

  }

  .selection-option {
    padding: 12px 24px;
    cursor: pointer;

    &:hover {
      background: $gray-60;
    }

    .radio {
      display: none;
    }
  }

  label {
    cursor: pointer;
  }
}

.selected-box {
  background: var(--light2);
  color: $color_1;
  position: relative;
  order: 0;
  padding: 12px 24px;
  cursor: pointer;

  @media (min-width:1007px) and (max-width:1920px) {
    max-width: calc(265px + (400 - 265) * ((100vw - 1007px) / (1920 - 1007)));
  }

  @media (min-width:768px) and (max-width:1006px) {
    max-width: calc(295px + (350 - 295) * ((100vw - 1007px) / (1006 - 768)));
  }

  @media (max-width:767px) {
    max-width: calc(242px + (415 - 242) * ((100vw - 320px) / (767 - 320)));
  }

  &::after {
    content: "\f078";
    font-family: "FontAwesome";
    position: absolute;
    height: 100%;
    width: 32px;
    right: 10px;
    top: 12px;
    transition: all 0.4s;

    [dir="rtl"] & {
      left: 10px;
      right: unset;
    }

  }
}

.search-box {
  input {
    width: 100%;
    padding: 12px 16px;
    font-size: 16px;
    position: absolute;
    z-index: 1;
    border: 2px solid var(--chart-dashed-border);
    opacity: 0;
    pointer-events: none;
    transition: all 0.4s;
    border-radius: 6px;

    @media (min-width:1007px) and (max-width:1920px) {
      max-width: calc(265px + (400 - 265) * ((100vw - 1007px) / (1920 - 1007)));
    }

    @media (min-width:768px) and (max-width:1006px) {
      max-width: calc(295px + (350 - 295) * ((100vw - 1007px) / (1006 - 768)));
    }

    @media (max-width:767px) {
      max-width: calc(242px + (415 - 242) * ((100vw - 320px) / (767 - 320)));
      padding: 9px 16px;
    }

    &:focus {
      outline: none;
    }
  }
}

/**=====================
    5.6 Select2 CSS Ends
==========================**/