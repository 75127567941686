/*! -----------------------------------------------------------------------------------

    Template Name: Cuba Admin
    Template URI: http://admin.pixelstrap.com/cuba/theme
    Description: This is Admin theme
    Author: Pixelstrap
    Author URI: https://themeforest.net/user/pixelstrap

-----------------------------------------------------------------------------------

    1.Base css
        1.1 Reset CSS
        1.2 Typography CSS

    2.Components css
        2.1 According CSS
        2.2 Alert CSS
        2.3 Avatars CSS
        2.4 Badge CSS
        2.5 Bookmark CSS
        2.6 Breadcrumb CSS
        2.7 Basic-card CSS      
        2.8 Buttons CSS
        2.9 Block-ui CSS
        2.10 Card CSS
        2.11 Color CSS
        2.12 Datatable CSS
        2.13 Datapicker CSS
        2.14 Dropdown CSS
        2.15 Form-input CSS
        2.16 Form-wizard CSS
        2.17 Forms CSS
        2.18 Icons CSS
        2.19 List CSS
        2.20 Loader CSS  
        2.21 Modal CSS
        2.22 Popover CSS
        2.23 Print CSS
        2.24 Radio CSS
        2.25 Ribbon CSS
        2.26 Range-slider CSS
        2.27 Switch CSS
        2.28 Tab CSS
        2.29 Table CSS
        2.30 Toasts CSS
        2.31 Touchspin CSS
        2.32 Tour CSS
        2.33 Tree CSS
        2.34 Typeahead-search CSS
        2.35 Scrollbar CSS


    3. Pages
        3.1 Add-product CSS
        3.2 Blog CSS
        3.3 Bookmark-app CSS
        3.4 Cart CSS
        3.5 Chart CSS
        3.6 Chat CSS
        3.7 Checkout CSS
        3.8 Comingsoon CSS
        3.9 Contacts CSS
        3.10 Dashboard_2 CSS
        3.11 Dashboard_3 CSS 
        3.12 Dashboard_4 CSS
        3.13 Dashboard_5 CSS
        3.14 Dashboard_6 CSS
        3.15 Dashboard_7 CSS
        3.16 Dashboard_8 CSS
        3.17 Dashboard_9 CSS
        3.18 Dashboard_10 CSS
        3.19 Dashboard_11 CSS
        3.20 Dashboard_default CSS
        3.21 Divider CSS
        3.22 Flash_icons CSS
        // 3.23 General-widget CSS
        3.23 Ecommerce CSS
        3.24 Email-application CSS
        3.25 Letter-box CSS
        3.26 Errorpage CSS
        3.27 Faq CSS
        3.28 File CSS
        3.29 File-manger CSS
        3.30 Gallery CSS
        3.31 Helper CSS
        3.32 Internationalization CSS
        3.33 Invoice CSS
        3.34 Job-search CSS
        3.35 Jsgrid CSS
        3.36 Editors CSS
        3.37 Kanban CSS
        3.38 Knowledgebase CSS
        3.39 Landing CSS
        3.40 Language CSS
        3.41 Learning CSS
        3.42 Login CSS
        3.43 Megaoption CSS
        3.44 Navigate-link CSS
        3.45 Order-history CSS
        3.46 Offcanvas CSS
        3.47 Page CSS
        3.48 Pricing CSS
        3.49 Progress CSS
        3.50 Projectlist CSS
        3.51 Placeholders CSS
        3.52 Rating CSS
        3.53 Social-app CSS
        3.54 Scrollspy CSS
        3.55 Sweet-alert CSS
        3.56 Sellers CSS
        3.57 Task CSS
        3.58 Timeline-v CSS
        3.59 User-profile CSS
        3.60 Wishlist CSS
    
    4. Themes
        4.1. Dark CSS
        4.2 Theme-customizer CSS
        4.3 Update CSS

    5. Layout
        5.1 Footer CSS
        5.2 Grid CSS
        5.3 Header CSS
        5.4 Navs CSS
        5.5 Search CSS
        5.6 Select2 CSS
        5.7 Sidebar CSS
        5.8 Rtl CSS
        5.9 Box-layout CSS

 */
// *** utils ***
@import "utils/variables";

:root {
    --theme-default: #7366FF;
    --theme-secondary: #838383;
    --light-background: rgba(242, 243, 247, 0.7);
    --body-font-color: #2F2F3B;
    --chart-border: #ECF3FA;
    --recent-chart-bg: #FCFCFD;
    --light-bg: #EEF1F6;
    --white: #fff;
    --light2: #F5F6F9;
    --sidebar-border: rgba(0, 0, 0, 0.1);
    --chart-text-color: rgba(82, 82, 108, 0.8);
    --recent-dashed-border: rgba(82, 82, 108, 0.3);
    --chart-dashed-border: rgba(82, 82, 108, 0.2);
    --chart-progress-light: rgba(82, 82, 108, 0.1);
    --recent-box-bg: #F2F4F7;
    --recent-border: rgba(97, 101, 122, 0.25);
    --course-light-btn: #F2F3F7;
    --course-bg: #F7F7F7;
    --balance-profile-bg: #E0DFEF;
    --view-grid-bg: #ECF3FA80;
    --view-border-marker: #cfcdfc;
    --light-slate: #F5F6F9;
    --product-shadow: 0px 1px 0px rgba(82, 82, 108, 0.15);
    --light-shade-primary: rgba(245, 244, 255);
    --rgb-primary: rgb(115, 102, 255);
    --f-lighter: #7f8183;
    --light-datatable-odd: #F5F6FA;
    --dark-light-color: rgba(10, 10, 10, 0.5);
    --lighter-gray: rgba(33, 37, 41, 0.75);


    // Charts color
    --primary-70: rgba(115, 102, 255, 0.7);
    --warning-70: rgba(255, 169, 65, 0.7);
    --success-70: rgba(84, 186, 74, 0.7);

    --fc-button-bg-color: var(--theme-default);
    --fc-button-active-bg-color: var(--theme-default);
    --fc-button-active-border-color: var(--theme-default);
    --fc-button-border-color: var(--theme-default);
    --fc-button-hover-bg-color: var(--theme-default);
    --fc-button-hover-border-color: var(--theme-default);

    // rating 
    --normal: #ECEAF3;
    --normal-shadow: #D9D8E3;
    --normal-mouth: #9795A4;
    --normal-eye: #595861;
    --active: #F8DA69;
    --active-shadow: #F4B555;
    --active-mouth: #F05136;
    --active-eye: #313036;
    --active-tear: #76b5e7;
    --active-shadow-angry: #e94f1d;

    // Bootstrap colors
    --bs-emphasis-color-rgb: rgba(242, 243, 247, 0.7);
    --bs-border-color: #e9edf1;
    --bs-table-bg: var(--bs-emphasis-color-rgb);
    --bs-heading-color: #333333;

    // Tagify
    /* Do not show the "remove tag" (x) button when only a single tag remains */
    .customLook {
        --tag-bg: var(--theme-default);
        --tag-hover: var(--theme-secondary);
        --tag-text-color: var(--white);
        --tags-border-color: silver;
        --tag-border-radius: 25px;
        --tag-text-color--edit: #111;
        --tag-remove-bg: var(--tag-hover);
        --tag-pad: .6em 1em;
        --tag-inset-shadow-size: 1.35em;
        --tag-remove-btn-bg--hover: #000;
        --tagify-dd-color-primary: var(var(--theme-default));
        --tagify-dd-bg-color: var(--course-light-btn);
    }

    // tables
    .table {
        --bs-table-color-state: initial;

        &.table-inverse {
            --bs-table-bg: #292b2c;
        }

        &.table-striped {
            &.bg-primary {
                --bs-table-bg: unset;
            }
        }

        --bs-body-bg: auto;
    }
}

.dark-only {
    --light-background: rgba(31, 32, 40, 0.65);
    --recent-chart-bg: #262932;
    --light-bg: #1d1e26;
    --white: #262932;
    --sidebar-border: rgba(255, 255, 255, 0.1);
    --light2: #1d1e26;
    --chart-border: #374558;
    --recent-box-bg: #1d1e26;
    --recent-border: #374558;
    --body-font-color: rgba(255, 255, 255, 0.6);
    --chart-text-color: rgba(101, 101, 113, 1);
    --chart-dashed-border: rgba(135, 135, 136, 0.2);
    --chart-progress-light: #202128;
    --course-light-btn: var(--light-bg);
    --course-bg: rgba(31, 32, 40, 0.65);
    --view-grid-bg: var(--light-bg);
    --view-border-marker: #2c2c45d4;
    --light-slate: #1c1d26;
    --light-datatable-odd: #191e27;
    --product-shadow: 0px 1px 0px rgb(55 70 87);
    --light-shade-primary: rgba(46, 53, 67, 1);
    --light-gray-1: linear-gradient(0deg, #FCFCFD, #FCFCFD),
        linear-gradient(0deg, #FFFFFF, #FFFFFF);
    --bs-body-bg: rgba(255, 255, 255, 0.6);
    --bs-table-bg: transparent;
    --light-bg-primary: rgba(239, 238, 255);
    --lighter-gray: rgba(255, 255, 255, 0.2);

    // Bootstrap
    --bs-heading-color: rgba(255, 255, 255, 0.7);
    --bs-emphasis-color-rgb: var(--light-background);
    --bs-border-color: var(--recent-chart-bg);

    .table {
        &.table-inverse {
            --bs-table-bg: #262932;
        }

        &.table-striped {
            &.bg-primary {
                --bs-table-bg: unset;
            }
        }
    }
}

// *** Utils ***
@import "utils/mixin";


// *** base ***
@import "base/reset";
@import "base/typography";

//  *** components ***
@import "components/according";
@import "components/alert";
@import "components/avatars";
@import "components/badge";
@import "components/bookmark";
@import "components/breadcrumb";
@import "components/basic-card";
@import "components/buttons";
@import "components/block-ui";
@import "components/card";
@import "components/datatable";
@import "components/datepicker";
@import "components/dropdown";
@import "components/form-input";
@import "components/form-wizard";
@import "components/forms";
@import "components/icons";
@import "components/list";
@import "components/loader";
@import "components/modal";
@import "components/popover";
@import "components/print";
@import "components/radio";
@import "components/ribbon";
@import "components/range-slider";
@import "components/switch";
@import "components/tab";
@import "components/table";
@import "components/toasts";
@import "components/touchspin";
@import "components/tour";
@import "components/tree";
@import "components/typeahead-search";
@import "components/scrollbar";

//	*** pages ***

@import "pages/add-product";
@import "pages/blog";
@import "pages/bookmark-app";
@import "pages/cart";
@import "pages/chart";
@import "pages/chat";
@import "pages/checkout";
@import "pages/comingsoon";
@import "pages/contacts";
@import "pages/dashboard_2";
@import "pages/dashboard_3";
@import "pages/dashboard_4";
@import "pages/dashboard_5";
@import "pages/dashboard_6";
@import "pages/dashboard_7";
@import "pages/dashboard_8";
@import "pages/dashboard_9";
@import "pages/dashboard_10";
@import "pages/dashboard_11";
@import "pages/dashboard_default";
@import "pages/divider";
@import "pages/flash_icons";
@import "pages/general-widget";
@import "pages/ecommerce";
@import "pages/email-application";
@import "pages/letter_box";
@import "pages/errorpage";
@import "pages/faq";
@import "pages/file";
@import "pages/file-manager";
@import "pages/gallery";
@import "pages/helper";
@import "pages/internationalization";
@import "pages/invoice";
@import "pages/job-search";
@import "pages/jsgrid";
@import "pages/editors";
@import "pages/kanban";
@import "pages/knowledgebase";
@import "pages/landing";
@import "pages/language";
@import "pages/learning";
@import "pages/login";
@import "pages/megaoption";
@import "pages/navigate-links";
@import "pages/order-history";
@import "pages/offcanvas";
@import "pages/page";
@import "pages/pricing";
@import "pages/progress";
@import "pages/projectlist";
@import "pages/placeholders";
@import "pages/rating";
@import "pages/social-app";
@import "pages/scrollspy";
@import "pages/sweet-alert";
@import 'pages/sellers';
@import "pages/task";
@import "pages/timeline-v";
@import "pages/user-profile";
@import "pages/wishlist";

// *** layout *** 

@import "layout/footer";
@import "layout/grid";
@import "layout/header";
@import "layout/navs";
@import "layout/search";
@import "layout/select2";
@import "layout/sidebar";
@import "layout/rtl";
@import "layout/box-layout";

/* ---------------------
	*** themes ***
-----------------------*/

@import "themes/theme-customizer";
@import "themes/update";
@import "themes/dark";