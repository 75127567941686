/**=====================
    3.17 Dashboard_9 Start
==========================**/

.compare-order {
    transition: 0.5s;

    .compare-icon {
        width: calc(40px + (45 - 40) * ((100vw - 320px) / (1920 - 320)));
        height: calc(40px + (45 - 40) * ((100vw - 320px) / (1920 - 320)));
        border-radius: 10px;
        background-color: var(--white);
        display: flex;
        justify-content: center;
        align-items: center;

        svg {
            width: 20px;
            height: 20px;
            transition: 0.5s;
        }
    }

    .card-body {
        .common-space {
            margin-bottom: 12px;

            h4 {
                color: var(--body-font-color);
            }
        }

        .progress {
            background: linear-gradient(180.36deg, #CFDCE8 -32.82%, #FFFFFF 132.82%);
            height: 9px;
            margin-bottom: 10px;
        }

        .user-growth {
            font-size: 12px;
            margin-right: 4px;
        }

        .user-text {
            color: rgba($badge-light-color, 0.8);
            font-size: 14px;

            @media (max-width:1469px) and (min-width:1400px) {
                display: none;
            }
        }
    }

    &:hover {
        transform: translateY(-5px);
        transition: 0.5s;

        .card-header {
            svg {
                transition: 0.5s;
                transform: scale(1.08);
            }
        }
    }
}

.crm-card {
    background-image: url(../images/dashboard-9/bg.jpg);
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;

    .card-body {
        h4 {
            color: $white;
            font-size: calc(18px + (22 - 18) * ((100vw - 320px) / (1920 - 320)));
        }

        p {
            width: 65%;
            color: $white;
            margin-bottom: 35px;

            @media (max-width:1830px) and (min-width:1450px) {
                width: 62%;
            }

            @media (max-width:1780px) {
                display: -webkit-box;
                max-width: 100%;
                height: 44px;
                -webkit-line-clamp: 2;
                -webkit-box-orient: vertical;
                overflow: hidden;
                text-overflow: ellipsis;
            }

            @media (max-width:1459px) {
                display: -webkit-box;
                height: 44px;
                -webkit-line-clamp: 2;
                -webkit-box-orient: vertical;
                overflow: hidden;
                text-overflow: ellipsis;
            }

            @media (max-width:991px) and (min-width:768px) {
                width: 70%;
            }

            @media (max-width:767px) {
                margin-bottom: 0;
            }

            @media (max-width:617px) {
                width: 60%;
                margin-bottom: 14px;
            }
        }
    }

    .crm-img {
        height: 138px;
        position: absolute;
        right: 12px;
        bottom: 8px;
        animation: bounce-effect 3s infinite ease;

        @media (max-width:1874px) and (min-width:1820px) {
            height: 125px;
            right: 12px;
            bottom: 16px;
        }

        @media (max-width:1819px) and (min-width:1626px) {
            height: 118px;
            right: 8px;
            bottom: 10px;
        }

        @media (max-width:1625px) and (min-width:1521px) {
            height: 100px;
            right: 12px;
            bottom: 40px;
        }

        @media (max-width:1520px)and (min-width:1400px) {
            height: 88px;
            right: 4px;
            bottom: 44px;
        }

        @media (max-width:1419px) and (min-width:1400px) {
            bottom: 52px;
        }

        @media (max-width:1000px) and (min-width:851px) {
            height: 122px;
        }

        @media (max-width:850px) and (min-width:768px) {
            height: 112px;
            right: 8px;
        }

        @media (max-width:416px) {
            height: 100px;
            right: 8px;
            bottom: 12px;
        }
    }
}

// Pipeline breakdown
.pipeline-data {
    display: flex;
    justify-content: space-between;
    align-items: center;

    @media (max-width:1536px) and (min-width:1400px) {
        justify-content: center;
    }

    @media (max-width:698px) and (min-width:576px) {
        justify-content: center;
    }

    [class*='dotted-'] {
        width: 8px;
        height: 8px;
        border-radius: 50%;

        @media (max-width:1673px) and (min-width:1400px) {
            display: none;
        }

        @media (max-width:740px) {
            display: none;
        }
    }

    .common-flex {
        align-items: baseline;

        span {
            color: rgba($badge-light-color, 0.8);
        }

        @media (max-width:1697px) and (min-width:1400px) {
            gap: 6px;
        }

        @media (max-width:1536px) and (min-width:1400px) {
            display: none;
        }

        @media (max-width:698px) and (min-width:576px) {
            display: none;
        }
    }
}

.revenue-chart-container {
    margin: -23px -10px -26px -16px;
}

// Todo list
.crm-todo-list {
    li {
        cursor: pointer;
        border-bottom: 1px solid var(--chart-dashed-border);
        padding: 12px 0;
        gap: 16px;

        @media (max-width:1560px) and (min-width:1400px) {
            gap: 12px;
        }

        &:first-child {
            padding-top: 0;
        }

        &:last-child {
            border-bottom: unset;
            padding-bottom: 0;
        }

        span {
            $line-name: primary,
                secondary,
                success,
                danger,
                info,
                light,
                dark,
                warning;
            $line-color: $primary-color,
                $secondary-color,
                $success-color,
                $danger-color,
                $info-color,
                $light-color,
                $dark-color,
                $warning-color;

            @each $var in $line-name {
                $i: index($line-name, $var);

                &.l-line-#{$var} {
                    height: 48px;
                    width: 3px;
                    border-radius: 4px;
                    background: nth($background-color, $i);

                    &:last-child {
                        margin-bottom: 0;
                    }
                }
            }
        }

        p {
            @media (max-width:1880px) {
                white-space: nowrap;
                text-overflow: ellipsis;
                overflow: hidden;
                max-width: 200px;
            }

            @media (max-width:1723px) and (min-width:1519px) {
                max-width: 158px;
            }

            @media (max-width:1518px) and (min-width:1400px) {
                max-width: 130px;
            }

            @media (max-width:843px) and (min-width:768px) {
                max-width: 200px;
            }

            @media (max-width:368px) {
                max-width: 145px;
            }
        }

        .form-check {
            .form-check-input {
                border-color: rgba($badge-light-color, 0.8);
            }
        }

        span {
            color: rgba($badge-light-color, 0.8);
        }
    }
}

// Finance overview
.finance-wrapper {
    .common-flex {
        @media (max-width:338px) {
            gap: 6px;
        }

        @media (max-width:322px) {
            flex-direction: column;
        }

        h6 {
            &:last-child {
                border-left: 1px solid var(--chart-dashed-border);
                padding-left: 16px;

                @media (max-width:338px) and (min-width:323px) {
                    padding-left: 6px;
                }

                @media (max-width:322px) {
                    border-left: unset;
                    padding-left: 0;
                }
            }
        }
    }

    .finance-chart-wrapper {
        @media (max-width:767px) {
            margin-bottom: -20px;
        }
    }
}

// Deals table
.deal-table {
    &.recent-table {

        thead,
        tbody {
            tr {

                th,
                td {
                    min-width: auto;
                    color: $badge-light-color;

                    &:first-child {
                        padding-left: 20px;
                    }

                    @media (max-width:1704px) and (min-width:1400px) {
                        &:first-child {
                            display: none;
                        }

                        &:nth-child(2) {
                            padding-left: 20px;
                        }
                    }

                    &:last-child {
                        padding-right: 20px;
                    }

                    @media (max-width:1591px) and (min-width:1400px) {
                        &:first-child {
                            display: none;
                        }

                        &:nth-child(2) {
                            padding-left: 20px;
                        }
                    }

                    @media (max-width:1450px) and (min-width:1200px) {
                        &:nth-child(3) {
                            display: none;
                        }
                    }

                    @media (max-width:1064px) {
                        &:first-child {
                            min-width: 52px;
                        }

                        &:nth-child(2) {
                            min-width: 116px;
                        }

                        &:last-child {
                            min-width: 90px;
                        }
                    }

                    @media (max-width:899px) {
                        &:first-child {
                            min-width: 98px;
                        }

                        &:nth-child(2) {
                            min-width: 135px;
                        }

                        &:nth-child(3) {
                            min-width: 100px;
                        }

                        &:last-child {
                            min-width: 135px;
                        }
                    }
                }
            }
        }

        thead {
            tr {
                th {
                    color: $badge-light-color;
                }
            }
        }

    }
}

// Leads table
.lead-table {
    &.recent-table {
        &.table-responsive {
            div.dt-container {
                .dt-layout-row {
                    .dt-layout-cell {
                        .dt-search {
                            right: 60px;

                            @media (max-width:1000px) {
                                display: none;
                            }
                        }
                    }
                }
            }
        }

        thead {
            tr {
                th {
                    color: $badge-light-color;
                }
            }
        }

        thead,
        tbody {
            tr {

                th,
                td {
                    min-width: auto;
                    color: $badge-light-color;

                    &:first-child {
                        padding-left: 20px;
                    }

                    &:last-child {
                        padding-right: 20px;

                        @media (max-width:1312px) {
                            .btn {
                                padding: 6px 16px;
                            }
                        }
                    }

                    .common-flex {
                        .lead-img {
                            border-radius: 8px;
                            height: 36px;

                            @media (max-width:1629px) {
                                height: 28px;
                            }
                        }
                    }

                    &:nth-child(4) {
                        .common-flex {
                            gap: 5px;
                        }
                    }

                    @media (max-width:1670px) and (min-width:1200px) {
                        &:nth-child(3) {
                            display: none;
                        }
                    }

                    @media (max-width:1608px) and (min-width:1200px) {
                        &:nth-child(3) {
                            display: none;
                        }
                    }

                    @media (max-width:1363px) and (min-width:1200px) {
                        &:nth-child(2) {
                            display: none;
                        }
                    }

                    @media (max-width:1199px) and (min-width:1200px) {
                        &:first-child {
                            min-width: 128px;
                        }

                        &:nth-child(4) {
                            min-width: 104px;
                        }
                    }

                    @media (max-width:1199px) {
                        &:first-child {
                            min-width: 100px;
                        }

                        &:nth-child(4) {
                            min-width: 114px;
                        }
                    }

                }
            }
        }
    }
}

// Lead source 
.main-lead-source {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: -100px;

    .lead-source-1 {
        width: 200px;
        height: 200px;
        position: relative;
        border-radius: 50%;
        background-color: rgba($primary-color, 0.9);
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 40px 0 80px 20px;
        text-align: center;

        @media (max-width:1920px) and (min-width:1400px) {
            width: calc(120px + (200 - 120) * ((100vw - 1400px) / (1920 - 1400)));
            height: calc(120px + (200 - 120) * ((100vw - 1400px) / (1920 - 1400)));
        }

        @media (max-width:1610px) and (min-width:1411px) {
            margin: 60px 0 100px 20px;
        }

        @media (max-width:1410px) and (min-width:1400px) {
            margin: 70px 0 120px 20px;
        }

        @media (max-width:1399px) and (min-width:1211px) {
            margin: 30px 0 80px 20px;
        }

        @media (max-width:1210px) {
            margin: 40px 0 90px 20px;
        }

        @media (max-width:1263px) and (min-width:768px) {
            width: calc(164px + (200 - 164) * ((100vw - 768px) / (1920 - 768)));
            height: calc(164px + (200 - 164) * ((100vw - 768px) / (1920 - 768)));
        }

        @media (max-width:992px) and (min-width:768px) {
            margin-top: 60px;
        }

        @media (max-width:767px) {
            width: calc(130px + (200 - 130) * ((100vw - 320px) / (1920 - 320)));
            height: calc(130px + (200 - 130) * ((100vw - 320px) / (1920 - 320)));
            margin: 20px 0 70px 20px;
        }

        @media (max-width:585px) and (min-width:576px) {
            margin: 42px 0 92px 32px;
        }
    }

    .lead-source-2 {
        width: 100px;
        height: 100px;
        border-radius: 50%;
        background-color: rgba($warning-color, 0.9);
        display: flex;
        justify-content: center;
        align-items: center;
        position: absolute;
        top: 0;
        right: -35%;
        transform: translateY(8%);
        z-index: 1;

        @media (max-width:1920px) and (min-width:1400px) {
            width: calc(70px + (100 - 70) * ((100vw - 1400px) / (1920 - 1400)));
            height: calc(70px + (100 - 70) * ((100vw - 1400px) / (1920 - 1400)));
        }

        @media (max-width:1410px) and (min-width:1400px) {
            right: -42%;
        }

        @media (max-width:1263px) and (min-width:768px) {
            width: calc(85px + (100 - 85) * ((100vw - 768px) / (1920 - 768)));
            height: calc(85px + (100 - 85) * ((100vw - 768px) / (1920 - 768)));
        }

        @media (max-width:767px) {
            width: calc(75px + (100 - 75) * ((100vw - 320px) / (1920 - 320)));
            height: calc(75px + (100 - 75) * ((100vw - 320px) / (1920 - 320)));
        }
    }
}

.lead-source-3 {
    width: 150px;
    height: 150px;
    border-radius: 50%;
    background-color: rgba($success-color, 0.9);
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    bottom: 20%;
    right: -90%;
    transform: translate(-50%, 50%);

    @media (max-width:1920px) and (min-width:1400px) {
        width: calc(80px + (150 - 80) * ((100vw - 1400px) / (1920 - 1400)));
        height: calc(80px + (150 - 80) * ((100vw - 1400px) / (1920 - 1400)));
    }

    @media (max-width:1599px) {
        right: -85%;
        bottom: 16%;
    }

    @media (max-width:1263px) and (min-width:768px) {
        width: calc(120px + (150 - 120) * ((100vw - 768px) / (1920 - 768)));
        height: calc(120px + (150 - 120) * ((100vw - 768px) / (1920 - 768)));
    }

    @media (max-width:767px) {
        width: calc(95px + (150 - 95) * ((100vw - 320px) / (1920 - 320)));
        height: calc(95px + (150 - 95) * ((100vw - 320px) / (1920 - 320)));
    }
}

.source-txt {

    span,
    h5 {
        color: $white;
    }

    h5 {
        font-size: calc(16px + (22 - 16) * ((100vw - 320px) / (1920 - 320)));
    }
}

// Sales chart
#sales-chart {
    .apexcharts-legend {
        gap: 12px;

        @media (max-width:1615px) {
            gap: 0;
        }
    }
}

.sales-week-container {
    margin: 0 -12px -40px -12px;
}

.pipeline-chart-container {
    margin-bottom: -12px;
}

.sales-chart-wrapper {
    @media (max-width:767px) {
        margin-bottom: -40px;
    }
}

//  Total sale
.main-sale {
    .sales-chart-wrap {
        #sales-chart1 {
            margin-bottom: -30px;
        }
    }

    .sale-details {
        display: flex;
        justify-content: space-between;
    }
}

// Sale
.sales-header {
    span {
        @media (max-width:1650px) and (min-width:1400px) {
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            max-width: 100px;
        }

        @media (max-width:715px) {
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            max-width: 100px;
        }
    }
}

// Revenue Growth 
.schedule-card {
    .card-header-right-icon {
        .btn {
            @media (max-width:1524px) and (min-width:1400px) {
                display: none;
            }

            @media (max-width:660px) {
                display: none;
            }
        }
    }
}

// Sales
.sales-data {
    justify-content: space-around;
    align-items: center;

    @media (max-width:1490px) and (min-width:1431px) {
        justify-content: flex-start;
        gap: 8px;
    }

    @media (max-width:1430px) and (min-width:1400px) {
        display: none;
    }

    @media (max-width:635px) and (min-width:576px) {
        justify-content: flex-start;
        align-items: center;
        gap: 10px;
    }

    li {
        .sale-value {
            width: 10px;
            height: 10px;
            border-radius: 50%;

            &.primary-20 {
                background-color: rgba($primary-color, 0.2);
            }

            &.primary-40 {
                background-color: rgba($primary-color, 0.4);
            }

            &.primary-70 {
                background-color: rgba($primary-color, 0.7);
            }
        }

        span {
            &:last-child {
                font-size: 13px;
            }
        }
    }
}

.week-header {
    .card-header-right-icon {
        @media (max-width: 1579px) {
            display: none;
        }
    }
}

/**=====================
    3.17 Dashboard_9 End
==========================**/