/**=====================
    2.16 Form-wizard CSS Start
==========================**/
/*Form Wizard One Start*/

.form-wizard {
	.tab {
		display: none;
	}

	.step {
		@include pixelstrap-size(10px, 10px);
		margin: 0 2px;
		background-color: var(--theme-default);
		border: none;
		border-radius: 50%;
		display: inline-block;
		opacity: 0.5;

		&.active {
			opacity: 1;
		}

		&.finish {
			background-color: var(--theme-secondary);
		}
	}
}

/*Form Wizard One ends*/

/*Form Wizard Four Start*/
.wizard-4 {
	.action-bar {
		position: absolute;
		bottom: 50px;
		right: 50px;

		[dir="rtl"] & {
			right: unset;
			left: 50px;
		}
	}

	.action-bar {
		.btn {
			float: right;
			margin: 0 5px;
			background-color: #e3e0ff !important;
			border-color: #e3e0ff !important;
			color: var(--theme-default);
		}

		.buttonDisabled {
			color: $semi-dark;
			background-color: rgba($semi-dark, 0.05) !important;
			border: 1px solid rgba($semi-dark, 0.05) !important;
			cursor: text;
		}
	}

	.step-container {
		background: #f8f9fe;
		position: relative;
		margin: 0;
		padding: 0;
		border: 0 solid #e8ebf2;
		overflow: hidden;
		clear: right;
		width: 70%;

		div.content {
			display: block;
			position: absolute;
			float: left;
			text-align: left;
			overflow: auto;
			z-index: 1;
			width: 100%;
			-webkit-box-sizing: border-box;
			box-sizing: border-box;
			clear: both;

			[dir="rtl"] & {
				text-align: unset;
				text-align: right;
			}

			.wizard-title {
				width: 450px;
				margin: 0 auto;
			}
		}
	}

	.loader {
		display: none;
	}

	.msg-box {
		position: fixed;
		display: none;
		padding: 5px 10px;
		background-color: lighten($primary-color, 10%);
		top: 30px;
		border-radius: 5px;
		right: 60px;
		padding: 10px 20px;

		.content {
			padding: 0;
			float: left;
			padding-top: 3px;
			color: $white;
		}

		.close {
			color: $white;
			margin-left: 10px;
			opacity: 1;
			font-size: 18px;
			line-height: 1.4;

			[dir="rtl"] & {
				margin-right: 10px;
				margin-left: unset;
			}
		}
	}

	ul.anchor {
		float: left;
		width: 30%;
		height: calc(100vh - 300px);
		padding: 40px 30px;
		overflow: auto;

		.disabled {
			opacity: 0.7;
			color: $semi-dark;

			h4 {
				background-color: rgba($semi-dark, 0.15);
			}
		}

		.logo {
			padding-bottom: 20px;
		}

		li {
			position: relative;
			display: block;
			margin: 0;
			padding: 0;
			padding-bottom: 19px;
			float: left;
			clear: both;
			width: 100%;
			box-sizing: border-box;
			z-index: 0;

			&+li {
				a {
					padding-left: 80px;

					[dir="rtl"] & {
						padding-left: unset;
						padding-right: 80px;
					}
				}
			}

			&:last-child {
				position: absolute;
				bottom: 0;
				width: auto;
				padding-bottom: 0;
				left: 9%;

				[dir="rtl"] & {
					left: unset;
					right: 9%;
				}

				img {
					height: 300px;

					@media (max-width: 1199px) {
						height: 220px;
					}
				}
			}

			h4 {
				display: inline-block;
				background-color: rgba($primary-color, 0.2);
				padding: 16px 23px;
				border-radius: 100%;
				position: absolute;
				left: 0;
				top: 6px;
				width: 60px;
				@include pixelstrap-flex(flex, center, center);

				[dir="rtl"] & {
					left: unset;
					right: 0;
				}
			}

			a {
				display: block;
				position: relative;
				float: left;
				margin: 0;
				padding: 10px 20px;
				width: 100%;
				box-sizing: border-box;
				text-decoration: none;
				outline-style: none;
				z-index: 1;
				font-size: 18px;

				small {
					display: block;
					font-size: 14px;
				}

				.stepNumber {
					position: relative;
					float: left;
					width: 30px;
					text-align: center;
					padding: 5px;
					padding-top: 0;
				}

				&.done {
					h4 {
						color: $white;
						background: var(--theme-default);

						&:before {
							font-size: 12px;
							position: absolute;
							left: 5px;
							top: -3px;
							content: "\e64c";
							font-family: 'themify';
							background-color: $white;
							color: var(--theme-default);
							border-radius: 15px;
							padding: 2px;
							border: 1px solid;
						}
					}
				}

				&.error {
					color: $dark-gray;
					background: $pitch-color;
					border: 1px solid $danger-color;

					&:hover {
						color: $black;
					}
				}
			}
		}
	}
}

.wizard {
	max-width: 670px;
	margin: 0 auto;

	.wizard-content {
		box-shadow: unset;
		background-color: $white;
		border-radius: 5px;
	}
}

// vertical form wizard
.vertical-main-wizard {
	.header-vertical-wizard {
		background-color: var(--light-background);
		height: 100%;
		text-align: unset;

		.nav-link {
			border-bottom: 1px solid var(--recent-border);
			border-radius: 0;
			padding: 18px;

			&:last-child {
				border-bottom: 0;
			}
		}
	}

	.nav-pills {
		.nav-link.active {
			background-color: transparent;
		}

		&.show {
			>.nav-link {
				background-color: transparent;
			}
		}
	}

	.nav-link {
		.vertical-wizard {
			display: flex;
			align-items: center;
			gap: 14px;

			.stroke-icon-wizard {
				@include pixelstrap-size(33px, 33px);
				@include pixelstrap-flex(flex, center, center);
				background-color: var(--white);
				border-radius: 50%;
				position: relative;
				border: 1px solid $badge-light-color;

				i {
					margin-right: 0;
					color: $badge-light-color;
				}
			}

			.vertical-wizard-content {

				h6 {
					font-weight: 400;
					line-height: 1;
					color: var(--body-font-color);
				}

				p {
					color: var(--chart-text-color);
				}
			}
		}

		&:active,
		&.active {
			background-color: unset;

			.vertical-wizard {
				.stroke-icon-wizard {
					background-color: var(--theme-default);
					border: 1px solid var(--theme-default);

					i {
						color: $white;
						font-size: 18px;
					}

					&::before {
						content: '';
						@include pixelstrap-size(45px, 45px);
						border-radius: 50%;
						position: absolute;
						border: 1px solid $primary-color;

					}
				}

				.vertical-wizard-content {

					h6 {
						color: var(--theme-default);
					}
				}
			}
		}
	}

	.form-check {
		.form-check-label {
			margin-bottom: 0;
		}
	}

	.input-group> :not(:first-child):not(.dropdown-menu):not(.valid-tooltip):not(.valid-feedback):not(.invalid-tooltip):not(.invalid-feedback) {
		border-top-left-radius: 0;
		border-bottom-left-radius: 0;
	}
}

.basic-wizard {
	.stepper-horizontal {
		display: flex;
		width: 100%;
		margin: 0 auto;
		overflow: auto;
		margin-bottom: calc(14px + (24 - 14) * ((100vw - 320px) / (1920 - 320)));

		.step {
			position: relative;
			padding-inline: 24px;
			z-index: 2;
			width: 100%;

			&:first-child {
				padding-left: 0;

				[dir="rtl"] & {
					padding-left: 24px;
					padding-right: 0;
				}
			}

			&:last-child {
				padding-right: 0;

				[dir="rtl"] & {
					padding-right: 24px;
					padding-left: 0;
				}
			}

			&:last-child .step-bar-left,
			&:last-child .step-bar-right {
				display: none;
			}

			.step-circle {
				@include pixelstrap-size(32px, 32px);
				margin: 0 auto;
				border-radius: 50%;
				text-align: center;
				line-height: 1.8;
				font-size: 16px;
				font-weight: 600;
				z-index: 2;
				border: 2px solid $gray-60;
				background: var(--white);
			}

			&.done {
				.step-circle {
					background-color: var(--white);
					border: 2px solid $theme-body-sub-title-color;
					color: $primary-color;
					z-index: 4;
					position: relative;

					&:before {
						font-family: $font-awesome;
						font-weight: 600;
						content: "\f00c";
					}

					* {
						display: none;
					}
				}

				.step-title {
					color: $theme-body-sub-title-color;
				}
			}

			&.editing {
				.step-circle {
					background: var(--white);
					border-color: $theme-body-sub-title-color;
					color: var(--theme-default);
				}
			}

			.step-title {
				margin-top: 8px;
				font-size: 16px;
				font-size: calc(14px + (16 - 14) * ((100vw - 320px) / (1920 - 320)));
				font-weight: 600;
				white-space: nowrap;
				color: var(--chart-text-color);
			}

			.step-title,
			.step-optional {
				text-align: center;
			}

			.step-bar-left,
			.step-bar-right {
				position: absolute;
				top: calc(32px - 18px);
				height: 4px;
				background-color: var(--view-border-marker);
				border: solid var(--view-border-marker);
				border-width: 2px 0;
			}

			.step-bar-left {
				width: 100%;
				left: 50%;
				z-index: -1;

				[dir="rtl"] & {
					left: unset;
					right: 50%;
				}
			}

			.step-bar-right {
				width: 0;
				left: 50%;
				z-index: -1;
				transition: width 500ms ease-in-out;

				[dir="rtl"] & {
					right: 50%;
					left: unset;
				}
			}

			&.done .step-bar-right {
				background-color: var(--theme-default);
				border-color: var(--theme-default);
				z-index: 3;
				width: 100%;
			}
		}
	}

	form {
		&.none {
			display: none;
		}

		&.block {
			display: block;
		}

		.form-check-label {
			margin-bottom: 0;
		}
	}
}

.important-validation {
	.form-label {
		span {
			color: $danger-color;
		}
	}
}

.select-size {
	&.form-select {
		font-size: 14px;
	}
}

.wizard-footer {
	button {
		&.disabled {
			background-color: $light-dark;
		}

		&.back {
			background-color: $warning-color;
		}
	}
}

// Numbering wizard
.successful-form {
	@include pixelstrap-flex(flex, center, center);
	flex-direction: column;
	gap: 10px;
	padding-bottom: 24px;

	img {
		@include pixelstrap-size(calc(68px + (100 - 68) * ((100vw - 320px) / (1920 - 320))), calc(68px + (100 - 68) * ((100vw - 320px) / (1920 - 320))));
	}
}

#horizontal-wizard-tab,
#vertical-wizard-tab {
	.nav-link {
		&.active {
			h6 {
				color: $white;
			}
		}
	}
}

.inquiries-form {
	h6 {
		margin-bottom: 14px;
	}

	.choose-option {
		display: flex;
		flex-direction: column;
		justify-content: center;

		.form-check {
			&:last-child {
				margin-bottom: 0;
			}
		}
	}
}

.form-completed {
	img {
		height: 100px;
		margin: 0 auto;
		display: block;
	}

	h6 {
		text-align: center;
		margin-top: 24px;
	}
}

// Horizontal wrapper
.horizontal-wizard-wrapper {
	.main-horizontal-header {
		padding: 0 0 20px 0;

		.horizontal-options {
			display: flex;
			justify-content: space-around;
			flex-wrap: wrap;
			gap: 14px;

			@media (max-width:1182px) {
				justify-content: flex-start;
			}

			a {
				@include pixelstrap-flex(flex, center, center);

				.horizontal-wizard {
					@include pixelstrap-flex(flex, center, center);
					color: var(--body-font-color);
					transition: all 0.3s ease;

					.stroke-icon-wizard {
						i {
							font-size: 18px;
							color: var(--body-font-color);

							[dir="rtl"] & {
								margin-right: unset;
								margin-left: 10px;
							}
						}
					}
				}

				&.nav-link {

					&:active,
					&.active {
						.horizontal-wizard {
							color: var(--white);
							transition: all 0.3s ease;

							.stroke-icon-wizard {
								i {
									color: $white;
								}

								span {
									color: $white;
								}
							}
						}
					}
				}
			}
		}

		.form-check {
			margin-bottom: 0;
		}
	}

	.bank-search {
		margin-bottom: 22px;

		@media (max-width:767px) {
			margin-bottom: 0;
		}
	}

	.bank-selection {
		padding: 10px 0 0 0;

		.radio-wrapper {
			@media (max-width:401px) {
				justify-content: center;
			}

			li {
				display: flex;
				justify-content: center;
				align-items: center;
				height: 100%;

				.form-check-label {
					margin-bottom: 0;
					padding: 6px;

					img {
						height: calc(45px + (52 - 45) * ((100vw - 320px) / (1920 - 320)));
					}

					span {
						font-size: 14px;
					}
				}
			}
		}
	}

	&.vertical-variations {
		.main-horizontal-header {
			.horizontal-options {
				gap: 12px;

				@media (max-width:1400px) {
					justify-content: flex-start;
				}

				.nav-link {
					color: var(--body-font-color);

					.horizontal-wizard {
						gap: 10px;

						.stroke-icon-wizard {
							@include pixelstrap-size(30px, 30px);
							@include pixelstrap-flex(flex, center, center);
							background-color: $light-primary;
							border-radius: 6px;

							i {
								margin-right: 0;
							}
						}
					}

					&:active,
					&.active {
						background-color: unset;

						.horizontal-wizard {
							color: var(--body-font-color);

							.stroke-icon-wizard {
								background-color: var(--theme-default);

								i {
									margin-right: 0;
									color: var(--white);
								}
							}
						}
					}
				}
			}
		}

		.select-account {
			.radio-wrapper {
				li {
					padding: 20px;
					// text-align: unset;
					display: grid;
					width: 100%;
					height: 100%;
					place-content: baseline;
					text-align: unset;
					align-items: unset;
					max-width: unset;

					.form-check-label {
						justify-content: unset;
						display: flex;
						align-items: center;

						span {
							>span {
								+span {
									font-size: 13px;

									@media (max-width:400px) {
										display: none;
									}
								}
							}
						}
					}
				}
			}
		}
	}
}

.horizontal-wizard-wrapper {
	&.vertical-options {
		.main-horizontal-header {
			padding-bottom: 0;

			.horizontal-options {
				flex-direction: column;
				align-items: flex-start;
				justify-content: flex-start;
				gap: 12px;
			}
		}
	}
}

.authentication-options {
	.radio-wrapper {
		display: flex;
		gap: calc(8px + 8 * (100vw - 320px) / 1600);
		flex-wrap: wrap;
		justify-content: center;

		li {
			padding: 20px;
			display: grid;
			width: 100%;
			height: 100%;
			place-content: baseline;
			text-align: unset;
			align-items: unset;
			max-width: unset;

			.form-check-label {
				display: flex;
				align-items: center;
				text-align: left;
				gap: 14px;

				@media (max-width:400px) {
					align-items: baseline;
				}

				i {
					padding: 0;

					[dir="rtl"] & {
						padding: 0;
					}
				}

				span {
					>span {
						font-size: 16px;
						color: $bg-dark-color;
						font-weight: 500;

						+span {
							color: var(--chart-text-color);
							font-size: 14px;
						}
					}
				}
			}
		}
	}
}

.main-qr-code {
	.modal-toggle-wrapper {
		.modal-img {
			flex-direction: column;
			align-items: center;
			margin-bottom: 22px;

			@media (max-width:480px) {
				align-items: unset;
			}

			.qr-img {
				@media (max-width:480px) {
					text-align: center;
				}

				img {
					@include pixelstrap-size(100px, 100px);
				}
			}

			.qr-content {
				margin-top: 16px;

				.alert {
					align-items: unset;

					div {
						word-break: break-word;
					}
				}

				i {
					font-size: 20px;
					margin-right: 0;
				}
			}
		}

		.btn {
			display: block;
			margin: auto;
			margin-top: 12px;
		}
	}
}

.authentication-body {
	@include pixelstrap-flex(flex, center, center);
	flex-direction: column;
	margin: auto;
	text-align: center;

	img {
		height: calc(200px + (420 - 200) * ((100vw - 320px) / (1920 - 320)));
	}
}

// Shipping form
.shipping-form,
.checkout-cart {
	.shipping-border {
		border-right: 1px solid var(--recent-dashed-border);

		@media (max-width:1200px) {
			border-right: unset;
		}
	}

	.shipping-options {
		display: flex;
		align-items: center;
		background-color: $light-primary;
		flex-wrap: nowrap;
		margin-bottom: 18px;

		@media (max-width:768px) {
			flex-direction: column;
		}

		.nav-link {
			width: 100%;

			@media (min-width:1200px) and (max-width:1920px) {
				padding: calc(8px + (8 - 8) * ((100vw - 300px) / (1920 - 300))) calc(6px + (16 - 6) * ((100vw - 300px) / (1920 - 300)));
			}

			&.active {

				i {
					background-color: $light-primary;
					color: var(--theme-default);
					transition: all 0.3s ease-in-out;
				}

				h6 {
					color: $white;
				}
			}

			&:nth-child(2) {
				.cart-options {
					i {
						transform: scaleX(-1);
					}
				}
			}
		}

		.cart-options {
			display: flex;
			align-items: center;
			gap: 11px;

			i {
				font-size: calc(14px + (18 - 14) * ((100vw - 320px) / (1920 - 320)));
				color: $white;
				@include pixelstrap-size(40px, 40px);
				background-color: var(--theme-default);
				@include pixelstrap-flex(flex, center, center);
				border-radius: 50%;
				margin: 0;
				transition: all 0.3s ease-in-out;

				@media (min-width:1200px) and (max-width:1920px) {
					font-size: calc(14px + (18 - 14) * ((100vw - 1200px) / (1920 - 1200)));
					width: calc(30px + (40 - 30) * ((100vw - 1200px) / (1920 - 1200)));
					height: calc(30px + (40 - 30) * ((100vw - 1200px) / (1920 - 1200)));
				}
			}

			h6 {
				color: var(--theme-default);
			}
		}

	}

	.shipping-info {
		.table {
			thead {
				th {
					&:last-child {
						text-align: end;
					}

					white-space: nowrap;
				}
			}

			tbody {
				tr {
					td {
						img {
							height: 50px;
						}

						&:last-child {
							text-align: end;
						}

						span {
							color: var(--chart-text-color);
						}
					}

					&:last-child {
						td {
							border: 0;
						}
					}
				}
			}

			tfoot {
				font-weight: 400;

				tr {
					td {
						&:first-child {
							max-width: 370px;
						}

						white-space: nowrap;
					}

					td {
						&:last-child {
							min-width: 0;
							text-align: end;
						}
					}

					&:first-child {
						font-weight: 500;
					}

					&:last-child {
						font-weight: 600;

						td {
							border-bottom: 0;
						}
					}
				}
			}
		}


	}

	.shipping-content {
		height: 100%;
		padding: 0;

		.shipping-wizard {
			.shipping-title {
				@include pixelstrap-flex(flex, space-between, center);

				>.btn {
					background: none !important;
					border: 0;
					padding: 0 28px;

					i {
						color: var(--theme-default);
					}
				}
			}

			.collect-address {
				@include pixelstrap-flex(flex, space-between, center);
				margin-bottom: 8px;

				.card-icon {
					display: flex;
					align-items: center;
					gap: 12px;

					i {
						font-size: 16px;
						color: var(--theme-default);
					}
				}
			}

			.shipping-address {
				display: flex;
				flex-direction: column;
				gap: 8px;

				h6 {
					font-size: 15px;
					font-weight: 500;
				}
			}
		}

		>.active {
			&.finish-wizard1 {
				@include pixelstrap-flex(flex, center, center);
				height: calc(100% - 55px - 18px);

				@media (max-width:768px) {
					height: unset;
				}
			}
		}
	}

	.shipping-method {
		p {
			padding-left: 25px;
			color: var(--chart-text-color);

			[dir="rtl"] & {
				padding-right: 25px;
				padding-left: unset;
			}
		}
	}

	.proceed-next {
		padding-left: 8px;

		[dir="rtl"] & {
			padding-left: unset;
			padding-right: 8px;
		}
	}

	.proceed-prev {
		transform: scaleX(-1);
		padding-left: 8px;

		[dir="rtl"] & {
			transform: scaleX(1);
			padding-right: 8px;
			padding-left: unset;
		}
	}

	.payment-info-wrapper {
		.card-wrapper {
			@include pixelstrap-flex(flex, space-between, center);

			@media (max-width:768px) {
				justify-content: unset;
				align-items: flex-start;
				flex-direction: column;
			}

			img {
				height: 50px;
			}
		}

		.pay-info {
			display: block;

			>div {
				margin-bottom: 12px;
				display: flex;
				align-items: center;

				@media (max-width: 768px) {
					flex-direction: column;
					align-items: flex-start;
				}

			}
		}
	}

	.order-confirm {
		text-align: center;

		@media (max-width:1200px) {
			margin-bottom: 18px;
		}

		img {
			height: calc(95px + (140 - 95) * ((100vw - 320px) / (1920 - 320)));
		}
	}

	.finish-shipping {
		background-color: var(--light-background);
		@include pixelstrap-size(100%, 100%);
		padding-block: calc(100% - 85%);
		padding-inline: 12px;
		text-align: center;

		svg {
			width: calc(140px + (230 - 140) * ((100vw - 320px) / (1920 - 320)));
			height: calc(140px + (230 - 140) * ((100vw - 320px) / (1920 - 320)));
			animation: up-down 2.9s ease-in-out infinite;
		}
	}

	.form-select {
		font-size: 14px;
		color: var(--chart-text-color);
	}
}

// Student validation form
.avatar-upload {
	flex-direction: column;

	div:first-child {
		position: relative;
		max-width: 205px;
		margin: auto;

		.avatar-edit {
			position: absolute;
			right: 25px;
			z-index: 1;
			top: 4px;

			input {
				display: none;
			}

			label {
				display: inline-block;
				@include pixelstrap-size(35px, 35px);
				margin-bottom: 0;
				border-radius: 100%;
				background: var(--light-bg);
				border: 1px solid transparent;
				box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.12);
				cursor: pointer;
				font-weight: normal;
				transition: all 0.2s ease-in-out;

				&::after {
					content: "\f303";
					font-family: $font-awesome;
					color: var(--body-font-color);
					position: absolute;
					top: 8px;
					left: 3px;
					right: 0;
					text-align: center;
					margin: auto;
					font-weight: 600;

					[dir="rtl"] & {
						right: 3px;
						left: 0;
					}
				}
			}
		}

		.avatar-preview {
			@include pixelstrap-size(150px, 150px);
			position: relative;
			border-radius: 100%;
			border: 6px solid #F8F8F8;
			box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.1);
			margin: auto;

			>div {
				background-image: url('../images/forms/user.png');
				@include pixelstrap-size(100%, 100%);
				border-radius: 100%;
				background-size: cover;
				background-repeat: no-repeat;
				background-position: center;
			}
		}
	}

	h6 {
		text-align: center;
		padding-top: 14px;
	}
}

// Authentication
.email-verify {
	.card-wrapper {
		display: grid;
		place-content: center;

		>div {
			align-items: center;
		}

		.input-group> :not(:first-child):not(.dropdown-menu):not(.valid-tooltip):not(.valid-feedback):not(.invalid-tooltip):not(.invalid-feedback) {
			border-top-left-radius: 0;
			border-bottom-left-radius: 0;
		}
	}
}

.authenticate {
	@include pixelstrap-flex(flex, center, center);
	flex-direction: column;

	span {
		&:nth-of-type(1) {
			margin-top: 12px;
		}
	}

	h4 {
		margin-bottom: 12px;
	}

	img {
		height: calc(145px + (200 - 145) * ((100vw - 320px) / (1920 - 320)));
	}

	form {
		flex-direction: column;
		text-align: center;

		.otp-generate {
			display: flex;
			gap: 12px;
			margin: 14px 0;
			justify-content: center;
		}

		.form-control {
			width: calc(25px + (35 - 25) * ((100vw - 320px) / (1920 - 320)));
			height: calc(25px + (35 - 25) * ((100vw - 320px) / (1920 - 320)));
			border-radius: 5px;
			text-align: center;
			padding: 0;
			display: block;
			min-width: 0;
		}

		div {
			display: inline-grid;
			gap: 2px;
			margin-top: 12px;
		}

	}
}

.accordion {
	.accordion-item {
		.accordion-body {
			.choose-bank {
				.form-check {
					.form-check-label {
						color: var(--body-font-color);
					}
				}
			}
		}
	}
}

/*Form Wizard Four Ends*/
/**=====================
   2.16 Form-wizard CSS Ends
==========================**/